const chapter1 = {
  scenes: {
    workshop: {
      location: "willsWorkshop",
      beats: {
        intro: {
          lines: [
            {
              type: "narration",
              text: "Steam hisses through brass pipes as gears whir in the dimly lit workshop."
            },
            {
              type: "dialog",
              speaker: "willHoward",
              variation: "thinking",
              text: "Something's not right. My temporal displacement blueprints... they're missing."
            },
            {
              type: "dialog",
              speaker: "willHoward",
              variation: "surprised",
              text: "The security system hasn't been triggered. Whoever did this knew exactly what they were doing."
            },
            {
              type: "choice",
              text: "How should Will proceed?",
              options: [
                {
                  text: "Search for clues in the workshop",
                  next: {
                    chapter: "chapter1",
                    scene: "workshop",
                    beat: "searchWorkshop"
                  }
                },
                {
                  text: "Contact Inspector Brass immediately",
                  next: {
                    chapter: "chapter1",
                    scene: "workshop",
                    beat: "contactInspector"
                  }
                }
              ]
            }
          ],
          next: null
        },
        searchWorkshop: {
          lines: [
            {
              type: "narration",
              text: "Will meticulously examines his workshop, checking every drawer and cabinet."
            },
            {
              type: "dialog",
              speaker: "willHoward",
              variation: "thinking",
              text: "These scratches on the lock... they're fresh. And this residue..."
            },
            {
              type: "dialog",
              speaker: "willHoward",
              variation: "surprised",
              text: "Wait, I recognize this compound. It could only have come from one place."
            }
          ],
          next: {
            chapter: "chapter1",
            scene: "workshop",
            beat: "revelation"
          }
        },
        contactInspector: {
          lines: [
            {
              type: "narration",
              text: "The telegraph machine springs to life as Will sends an urgent message."
            },
            {
              type: "dialog",
              speaker: "inspectorBrass",
              variation: "neutral",
              text: "Mr. Howard, I came as quickly as I could. What seems to be the trouble?"
            },
            {
              type: "dialog",
              speaker: "willHoward",
              variation: "sad",
              text: "Inspector, someone has stolen my most dangerous invention plans."
            }
          ],
          next: {
            chapter: "chapter1",
            scene: "workshop",
            beat: "revelation"
          }
        },
        revelation: {
          lines: [
            {
              type: "dialog",
              speaker: "evelynGearhart",
              variation: "neutral",
              text: "Pardon the interruption, but I couldn't help overhearing."
            },
            {
              type: "dialog",
              speaker: "willHoward",
              variation: "surprised",
              text: "Who are you? How did you get in here?"
            },
            {
              type: "dialog",
              speaker: "evelynGearhart",
              variation: "thinking",
              text: "I'm Evelyn Gearhart, investigative journalist. And I believe I know who took your blueprints."
            },
            {
              type: "choice",
              text: "How should Will respond to Evelyn?",
              options: [
                {
                  text: "Trust her and listen",
                  next: {
                    chapter: "chapter2",
                    scene: "investigation",
                    beat: "intro"
                  }
                },
                {
                  text: "Dismiss her claims",
                  next: {
                    isGameOver: true
                  }
                }
              ]
            }
          ],
          next: null
        }
      }
    }
  }
};

const chapter2 = {
  scenes: {
    investigation: {
      location: "cityStreets",
      beats: {
        intro: {
          lines: [
            {
              type: "narration",
              text: "The streets buzz with the sound of steam engines and clockwork machines."
            },
            {
              type: "dialog",
              speaker: "evelynGearhart",
              variation: "neutral",
              text: "I've been tracking Professor Blackwood's activities for months. The theft matches his pattern."
            },
            {
              type: "dialog",
              speaker: "willHoward",
              variation: "thinking",
              text: "Thaddeus? But he was my mentor..."
            },
            {
              type: "choice",
              text: "What's Will's next move?",
              options: [
                {
                  text: "Confront Blackwood directly",
                  next: {
                    chapter: "chapter2",
                    scene: "confrontation",
                    beat: "intro"
                  }
                },
                {
                  text: "Gather more evidence first",
                  next: {
                    chapter: "chapter2",
                    scene: "investigation",
                    beat: "evidence"
                  }
                }
              ]
            }
          ],
          next: null
        },
        evidence: {
          lines: [
            {
              type: "narration",
              text: "Will and Evelyn spend hours piecing together clues."
            },
            {
              type: "dialog",
              speaker: "evelynGearhart",
              variation: "surprised",
              text: "Look at these documents. He's planning something big at the old factory."
            }
          ],
          next: {
            isGameOver: true
          }
        }
      }
    },
    confrontation: {
      location: "industrialFactory",
      beats: {
        intro: {
          lines: [
            {
              type: "dialog",
              speaker: "professorBlackwood",
              variation: "neutral",
              text: "I wondered how long it would take you to find me, Will."
            },
            {
              type: "dialog",
              speaker: "willHoward",
              variation: "angry",
              text: "Return my blueprints, Professor. You don't know what you're dealing with."
            }
          ],
          next: {
            isGameOver: true
          }
        }
      }
    }
  }
};

export const STORY_CONTENT = {
  chapters: {
    chapter1,
    chapter2
  }
};
