import React from 'react';
import { motion } from 'framer-motion';

export default function Loading({ error }) {
  if (error) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center p-4 relative overflow-hidden"
        style={{
          background: 'linear-gradient(45deg, #2F1810 0%, #4A2511 100%)',
        }}
      >
        {/* Decorative Corner Gears */}
        <div className="absolute top-0 left-0 w-32 h-32 pointer-events-none"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite',
          }}
        />
        <div className="absolute top-0 right-0 w-32 h-32 pointer-events-none"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite reverse',
          }}
        />
        <div className="absolute bottom-0 left-0 w-32 h-32 pointer-events-none"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite reverse',
          }}
        />
        <div className="absolute bottom-0 right-0 w-32 h-32 pointer-events-none"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite',
          }}
        />

        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-2xl font-playfair text-[#CD853F] mb-4 pointer-events-none"
          style={{
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          }}
        >
          Error Loading Game Assets
        </motion.div>
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-[#D4AF37] font-sourceSans text-center max-w-md pointer-events-none"
          style={{
            textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
          }}
        >
          {error}
        </motion.div>
      </div>
    );
  }

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center p-4 relative overflow-hidden"
      style={{
        background: 'linear-gradient(45deg, #2F1810 0%, #4A2511 100%)',
      }}
    >
      {/* Decorative Corner Gears */}
      <div className="absolute top-0 left-0 w-32 h-32 pointer-events-none"
        style={{
          background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
          opacity: 0.3,
          animation: 'spin 10s linear infinite',
        }}
      />
      <div className="absolute top-0 right-0 w-32 h-32 pointer-events-none"
        style={{
          background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
          opacity: 0.3,
          animation: 'spin 10s linear infinite reverse',
        }}
      />
      <div className="absolute bottom-0 left-0 w-32 h-32 pointer-events-none"
        style={{
          background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
          opacity: 0.3,
          animation: 'spin 10s linear infinite reverse',
        }}
      />
      <div className="absolute bottom-0 right-0 w-32 h-32 pointer-events-none"
        style={{
          background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
          opacity: 0.3,
          animation: 'spin 10s linear infinite',
        }}
      />

      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-3xl font-playfair text-[#D4AF37] mb-8 pointer-events-none"
        style={{
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        }}
      >
        Loading...
      </motion.div>

      <div className="w-64 h-4 bg-[#1A0F0A] rounded-full p-1 relative pointer-events-none"
        style={{
          boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.5), 0 2px 4px rgba(184, 134, 11, 0.2)',
        }}
      >
        <motion.div 
          initial={{ width: '0%' }}
          animate={{ 
            width: '100%',
            transition: { duration: 2, repeat: Infinity }
          }}
          className="h-full rounded-full pointer-events-none"
          style={{
            background: 'linear-gradient(90deg, #B8860B, #CD853F, #B8860B)',
            backgroundSize: '200% 100%',
            animation: 'gradient 2s linear infinite',
            boxShadow: '0 0 10px rgba(184, 134, 11, 0.3)',
          }}
        />
      </div>
    </div>
  );
}
