import React, { useState } from 'react';
import Button from '../components/ui/Button';
import Settings from '../components/modals/Settings';
import Credits from '../components/modals/Credits';
import { motion } from 'framer-motion';

export default function Home({ onStartGame }) {
  const [showSettings, setShowSettings] = useState(false);
  const [showCredits, setShowCredits] = useState(false);

  return (
    <div className="h-screen w-screen overflow-hidden"
      style={{
        background: 'linear-gradient(45deg, #2F1810 0%, #4A2511 100%)',
      }}
    >
      <div className="relative h-full w-full flex flex-col items-center justify-center p-4">
        {/* Background Image */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="absolute inset-0 z-0 pointer-events-none"
        >
          <img
            src={window.gameLogic.getSpriteUrl('titleBackground')}
            alt="Title Background"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50" />
        </motion.div>

        {/* Decorative Corner Gears */}
        <div className="absolute top-0 left-0 w-32 h-32 pointer-events-none z-10"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite',
          }}
        />
        <div className="absolute top-0 right-0 w-32 h-32 pointer-events-none z-10"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite reverse',
          }}
        />
        <div className="absolute bottom-0 left-0 w-32 h-32 pointer-events-none z-10"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite reverse',
          }}
        />
        <div className="absolute bottom-0 right-0 w-32 h-32 pointer-events-none z-10"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite',
          }}
        />

        {/* Main Content */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className="relative p-12 z-20 pointer-events-none"
          style={{
            background: 'linear-gradient(45deg, #2F1810 0%, #4A2511 100%)',
            border: '8px solid',
            borderImage: 'linear-gradient(45deg, #B8860B, #CD853F) 1',
            boxShadow: '0 0 30px rgba(184, 134, 11, 0.3)',
          }}
        >
          <h1 
            className="text-6xl mb-12 text-center font-playfair text-[#D4AF37] pointer-events-none"
            style={{
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            }}
          >
            Will Howard
          </h1>

          <div className="space-y-6 w-72 pointer-events-auto">
            <Button onClick={onStartGame}>
              Begin Adventure
            </Button>
            
            <Button onClick={() => setShowSettings(true)}>
              Settings
            </Button>
            
            <Button onClick={() => setShowCredits(true)}>
              Credits
            </Button>
          </div>
        </motion.div>
      </div>

      {showSettings && <Settings onClose={() => setShowSettings(false)} />}
      {showCredits && <Credits onClose={() => setShowCredits(false)} />}

      <style jsx>{`
        @keyframes spin {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
}
