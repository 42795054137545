export const CHARACTERS = {
  willHoward: {
    name: "Will Howard",
    spriteId: "willHoward",
    description: "A brilliant but reclusive inventor"
  },
  evelynGearhart: {
    name: "Evelyn Gearhart",
    spriteId: "evelynGearhart", 
    description: "A savvy and charismatic journalist"
  },
  professorBlackwood: {
    name: "Professor Thaddeus Blackwood",
    spriteId: "professorBlackwood",
    description: "Will's former mentor turned rival"
  },
  inspectorBrass: {
    name: "Inspector Reginald Brass",
    spriteId: "inspectorBrass",
    description: "A dedicated lawman"
  }
};

export const LOCATIONS = {
  willsWorkshop: {
    name: "Will's Workshop",
    spriteId: "willsWorkshop",
    description: "A cluttered space filled with inventions"
  },
  cityStreets: {
    name: "City Streets", 
    spriteId: "cityStreets",
    description: "Bustling steampunk cityscape"
  },
  mysteriousRuins: {
    name: "Mysterious Ruins",
    spriteId: "mysteriousRuins", 
    description: "Ancient sites with hidden secrets"
  },
  industrialFactory: {
    name: "Industrial Factory",
    spriteId: "industrialFactory",
    description: "Massive complex of machinery"
  }
};
