import React from 'react';

export default function Button({ children, onClick, variant = 'primary', className = '' }) {
  const baseClasses = 'transition-all duration-300 font-cinzel';
  
  const variants = {
    primary: `
      py-3 px-6 text-lg text-[#2F1810]
      relative overflow-hidden
      bg-gradient-to-r from-[#B8860B] to-[#CD853F] 
      hover:from-[#CD853F] hover:to-[#B8860B]
      rounded shadow-lg hover:shadow-xl
      transform hover:-translate-y-0.5
    `,
    subtle: `
      px-4 py-2 text-[#B8860B]
      hover:text-[#CD853F]
      relative
      after:content-['']
      after:absolute after:bottom-0 after:left-0
      after:w-full after:h-[1px]
      after:bg-gradient-to-r after:from-transparent after:via-[#B8860B] after:to-transparent
      after:opacity-0
      hover:after:opacity-100
      after:transition-opacity after:duration-300
    `,
    choice: `
      w-full p-4 text-left
      bg-gradient-to-r from-[#2F1810] to-[#4A2511]
      hover:from-[#4A2511] hover:to-[#2F1810]
      border-2 border-[#B8860B]
      hover:border-[#CD853F]
      rounded-lg
      text-[#D4AF37]
      hover:text-[#FFD700]
      shadow-md hover:shadow-lg
      transform hover:-translate-y-0.5
    `
  };

  const buttonStyle = {
    textShadow: variant === 'primary' ? '1px 1px 2px rgba(255,255,255,0.3)' : '1px 1px 2px rgba(0,0,0,0.5)',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  };

  return (
    <button 
      onClick={onClick}
      className={`${baseClasses} ${variants[variant]} ${className}`}
      style={buttonStyle}
    >
      {children}
    </button>
  );
}
