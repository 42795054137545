export const SPRITES = {
  "willHoward": {
    srcs: "assets/characters/willHoward-{variation}.png",
    variations: ['neutral', 'happy', 'sad', 'embarrassed', 'crying', 'surprised', 'angry', 'thinking'],
    width: 416,
    height: 624
  },
  "evelynGearhart": {
    srcs: "assets/characters/evelynGearhart-{variation}.png",
    variations: ['neutral', 'happy', 'sad', 'embarrassed', 'crying', 'surprised', 'angry', 'thinking'],
    width: 416,
    height: 624
  },
  "professorBlackwood": {
    srcs: "assets/characters/professorBlackwood-{variation}.png",
    variations: ['neutral', 'happy', 'sad', 'embarrassed', 'crying', 'surprised', 'angry', 'thinking'],
    width: 416,
    height: 624
  },
  "inspectorBrass": {
    srcs: "assets/characters/inspectorBrass-{variation}.png",
    variations: ['neutral', 'happy', 'sad', 'embarrassed', 'crying', 'surprised', 'angry', 'thinking'],
    width: 416,
    height: 624
  },
  "willsWorkshop": {
    srcs: "assets/locations/willsWorkshop.png",
    width: 1216,
    height: 960
  },
  "cityStreets": {
    srcs: "assets/locations/cityStreets.png",
    width: 1216,
    height: 960
  },
  "mysteriousRuins": {
    srcs: "assets/locations/mysteriousRuins.png",
    width: 1216,
    height: 960
  },
  "industrialFactory": {
    srcs: "assets/locations/industrialFactory.png",
    width: 1216,
    height: 960
  },
  "titleBackground": {
    srcs: "assets/backgrounds/title.png",
    width: 1216,
    height: 960
  }
};
