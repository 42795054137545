import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import ReactDOM from 'react-dom/client';
import WebFontProvider from './WebFontProvider';
import App from './App';

// Function to check if analytics should be enabled
const shouldEnableAnalytics = () => {
    return process.env.REACT_APP_DISABLE_ANALYTICS !== 'true';
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <WebFontProvider>
        <App />
        {shouldEnableAnalytics() && <Analytics />}
    </WebFontProvider>
);