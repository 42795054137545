import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CHARACTERS, LOCATIONS } from '../logic/gameData';
import Button from '../components/ui/Button';
import Settings from '../components/modals/Settings';
import Help from '../components/modals/Help';

export default function Story({ gameLogic, onLeaveStory }) {
  const [content, setContent] = useState(null);
  const [location, setLocation] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    const current = gameLogic.getCurrentContent();
    setContent(current.content);
    setLocation(current.location);
  }, []);

  const handleAdvance = () => {
    const result = gameLogic.advance();
    if (result.isGameOver) {
      onLeaveStory();
      return;
    }

    const current = gameLogic.getCurrentContent();
    setContent(current.content);
    setLocation(current.location);
  };

  const handleChoice = (index) => {
    const result = gameLogic.makeChoice(index);
    if (result.isGameOver) {
      onLeaveStory();
      return;
    }

    const current = gameLogic.getCurrentContent();
    setContent(current.content);
    setLocation(current.location);
  };

  if (!content) return null;

  return (
    <div className="relative h-screen w-screen overflow-hidden bg-[#1A0F0A]">
      {/* Menu Buttons */}
      <div className="absolute top-4 right-4 z-40 space-x-4">
        <Button variant="subtle" onClick={() => setShowHelp(true)}>
          Help
        </Button>
        <Button variant="subtle" onClick={() => setShowSettings(true)}>
          Settings
        </Button>
      </div>

      {/* Background */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="absolute inset-0 z-0 pointer-events-none"
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black/40 z-10" />
        <img
          src={gameLogic.getSpriteUrl(location)}
          alt={LOCATIONS[location].name}
          className="w-full h-full object-cover"
        />
      </motion.div>

      {/* Character */}
      <AnimatePresence mode="wait">
        {content.type === 'dialog' && !content.noSpeakerSprite && (
          <motion.div 
            key={content.speaker + content.variation}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute left-1/2 z-20 pointer-events-none"
            style={{
              transform: 'translateX(-50%)',
              bottom: '-40px',
              width: '416px',
              height: '624px',
              filter: 'drop-shadow(0 0 20px rgba(184, 134, 11, 0.2))'
            }}
          >
            <img
              src={gameLogic.getSpriteUrl(content.speaker, content.variation)}
              alt={CHARACTERS[content.speaker].name}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Dialog/Choice UI */}
      <div className="absolute bottom-0 left-0 right-0 p-6 z-30">
        <motion.div 
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="relative p-6 rounded-lg pointer-events-none"
          style={{
            background: 'linear-gradient(45deg, #2F1810 0%, #4A2511 100%)',
            border: '8px solid',
            borderImage: 'linear-gradient(45deg, #B8860B, #CD853F) 1',
            boxShadow: '0 0 30px rgba(184, 134, 11, 0.3)',
          }}
        >
          {/* Decorative Corner Gears */}
          <div className="absolute top-0 left-0 w-16 h-16"
            style={{
              background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
              opacity: 0.3,
              animation: 'spin 10s linear infinite',
            }}
          />
          <div className="absolute top-0 right-0 w-16 h-16"
            style={{
              background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
              opacity: 0.3,
              animation: 'spin 10s linear infinite reverse',
            }}
          />

          {content.type === 'dialog' && (
            <>
              <h3 
                className="text-2xl mb-4 font-playfair text-[#D4AF37]"
                style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}
              >
                {CHARACTERS[content.speaker].name}
              </h3>
              <p className="text-lg mb-6 font-sourceSans text-[#CD853F]">
                {content.text}
              </p>
              <div className="pointer-events-auto">
                <Button variant="subtle" onClick={handleAdvance}>
                  Continue...
                </Button>
              </div>
            </>
          )}

          {content.type === 'narration' && (
            <>
              <p 
                className="text-lg italic mb-6 font-sourceSans text-[#CD853F]"
                style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}
              >
                {content.text}
              </p>
              <div className="pointer-events-auto">
                <Button variant="subtle" onClick={handleAdvance}>
                  Continue...
                </Button>
              </div>
            </>
          )}

          {content.type === 'choice' && (
            <>
              <p 
                className="text-lg mb-6 font-sourceSans text-[#CD853F]"
                style={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}
              >
                {content.text}
              </p>
              <div className="space-y-3 pointer-events-auto">
                {content.options.map((option, index) => (
                  <Button
                    key={index}
                    variant="choice"
                    onClick={() => handleChoice(index)}
                  >
                    {option.text}
                  </Button>
                ))}
              </div>
            </>
          )}
        </motion.div>
      </div>

      {showSettings && <Settings onClose={() => setShowSettings(false)} />}
      {showHelp && <Help onClose={() => setShowHelp(false)} />}
    </div>
  );
}
