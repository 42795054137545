import React from 'react';
import { motion } from 'framer-motion';

export default function Help({ onClose }) {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/70 flex items-center justify-center z-50"
    >
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="modal-content relative w-[90%] max-w-[500px] p-8 pointer-events-none"
        style={{
          background: 'linear-gradient(45deg, #2F1810 0%, #4A2511 100%)',
          border: '8px solid',
          borderImage: 'linear-gradient(45deg, #B8860B, #CD853F) 1',
          boxShadow: '0 0 30px rgba(184, 134, 11, 0.3)',
        }}
      >
        {/* Decorative Corner Gears */}
        <div className="absolute top-0 left-0 w-16 h-16 pointer-events-none"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite',
          }}
        />
        <div className="absolute top-0 right-0 w-16 h-16 pointer-events-none"
          style={{
            background: 'radial-gradient(circle, #B8860B 20%, transparent 70%)',
            opacity: 0.3,
            animation: 'spin 10s linear infinite reverse',
          }}
        />

        <h2 
          className="text-3xl mb-8 text-center font-playfair text-[#D4AF37] pointer-events-none"
          style={{
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          }}
        >
          How to Play
        </h2>
        
        <div className="space-y-6 bg-[#1A0F0A] p-6 rounded pointer-events-none">
          <div>
            <h3 className="text-xl font-playfair text-[#CD853F] mb-3">Navigation</h3>
            <p className="text-[#D4AF37] font-sourceSans">
              Tap anywhere or press "Continue" to advance through the story
            </p>
          </div>
          
          <div className="border-t border-[#B8860B]/30 pt-6">
            <h3 className="text-xl font-playfair text-[#CD853F] mb-3">Choices</h3>
            <p className="text-[#D4AF37] font-sourceSans">
              Select choices to determine how the story unfolds
            </p>
          </div>
          
          <div className="border-t border-[#B8860B]/30 pt-6">
            <h3 className="text-xl font-playfair text-[#CD853F] mb-3">Settings</h3>
            <p className="text-[#D4AF37] font-sourceSans">
              Adjust volume and text speed in the settings menu
            </p>
          </div>
        </div>

        <button 
          onClick={onClose}
          className="w-full mt-8 py-3 px-6 text-lg font-playfair text-[#2F1810] pointer-events-auto z-52
            relative overflow-hidden transition-all duration-300
            bg-gradient-to-r from-[#B8860B] to-[#CD853F] 
            hover:from-[#CD853F] hover:to-[#B8860B]
            rounded shadow-lg hover:shadow-xl
            transform hover:-translate-y-0.5"
          style={{
            textShadow: '1px 1px 2px rgba(255,255,255,0.3)',
          }}
        >
          Close
        </button>
      </motion.div>
    </motion.div>
  );
}
